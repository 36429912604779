import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import AutoRefillLogo from '../Dashboard/AutoRefillLogo';
import UserMenuMobile from '../UserMenuMobile';
import { selectRefillHistoryData, selectRefillHistoryStatus } from '../../features/refill/refillSlice';
import { STATUS } from '../../../shared/enums';
import DashboardHistory from '../DashboardHistory';

/**
 * @param route
 * @returns {JSX.Element}
 * @constructor
 */
const ReferralPageContent = ({ route }) => {
  const refillHistory = useSelector(selectRefillHistoryData);
  const refillHistoryStatus = useSelector(selectRefillHistoryStatus);

  return (
    <main className='main dashboard referral'>
      <Helmet>
        <title>{route?.meta?.title}</title>
        <script src={YOTPO_SRC} async type='text/javascript' />
      </Helmet>
      <div className='dashboard-autorefill referral-main'>
        <AutoRefillLogo />
        <UserMenuMobile />
        <h2>Mila Rewards</h2>
        <div className='yotpo-widget-instance' data-yotpo-instance-id={YOTPO_ID} />
      </div>
      {
        (refillHistoryStatus !== STATUS.LOADING && refillHistory) && (
          <div className='dashboard-aside referral-aside'>
            <DashboardHistory refillHistory={refillHistory} />
          </div>
        )
      }
    </main>
  );
};

export default ReferralPageContent;
